import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';

import addToMailchimp from 'gatsby-plugin-mailchimp';

import {
  NewsletterWrapper,
  EmailWrapper,
  ContactFormWrapper
} from './newsletter.style';

// close button for modal
const CloseModalButton = () => (
    <Button
        className="modalCloseBtn"
        variant="fab"
        onClick={() => closeModal()}
        icon={<i className="flaticon-plus-symbol" />}
    />
);


const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
  onChange
}) => {

  const ModalContent = (msg) => ({
                          textArea
                        }) => {
    return (
        <EmailWrapper>
          <Box>
            <Text
                content={msg}
                fontSize="['14px', '14px']"
                fontWeight='400'
                color='#f8f9fa'
                lineHeight='1.7'
                textAlign="['center', 'center', 'center', 'left', 'left']"
            />
          </Box>
        </EmailWrapper>
    )
  };

  const handleEmailModal = (msg) => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
        timeoutSeconds: 2,
      },
      component: ModalContent(msg),
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  const [state, setState] = useState({
    value: '',
    email_response: ''
  });

  const handleOnChange = event => {
    setState({
      ...state,
      value: event
    });
    onChange(event);
  };

  const onSubmit = () => {
    console.log(state.value);
    addToMailchimp(state.value) // listFields are optional if you are only capturing the email address.
        .then(data => {
          setState({
            ...state,
            email_response: data
          });
          console.log(data);
          if (data.msg.includes("is already subscribed")) {
            handleEmailModal("Thank you for subscribing!")
          }
          else {
            handleEmailModal(data.msg);
          }
        })
        .catch(() => {
          console.error("Mailchimp error")
        })
  };

   return (
        <Box {...sectionWrapper} as="section">
          <Container>
            <NewsletterWrapper>
              <Box {...textArea}>
                <Heading content="Register for Updates" {...title} />
                  <Text
                      content="Sign up to receive updates as the wxchange goes operational and to request beta access!"
                      {...description}
                  />
              </Box>
              <Box {...buttonArea}>
                <ContactFormWrapper>
                  <Input
                      inputType="email"
                      label="Email address"
                      iconPosition="right"
                      isMaterial={true}
                      className="email_input"sS
                      arial-label="email"
                      // value={state.value}
                      onChange={handleOnChange}
                  />
                  <Button {...buttonStyle}
                          title="SIGN UP"
                          type="submit"
                          onClick={onSubmit}
                  />
                </ContactFormWrapper>
              </Box>
            </NewsletterWrapper>
          </Container>
        </Box>
    )
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  onChange: PropTypes.func,
};

Newsletter.defaultProps = {
  sectionWrapper: {
    id: 'newsletter_section',
    as: 'section',},
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '80px', '100px'],
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    colors: 'secondaryWithBg',
    color: '#03103b',
  },
  onChange: () => {}
};

export default Newsletter;
