import DataProducers from '../../assets/image/saasClassic/data_producers.png';
import Developers from '../../assets/image/saasClassic/developers.png';
import DataConsumers from '../../assets/image/saasClassic/data_consumers.png';

import { ic_build } from 'react-icons-kit/md/ic_build';
import { ic_code } from 'react-icons-kit/md/ic_code';
import { ic_cloud_download } from 'react-icons-kit/md/ic_cloud_download';


export const SCREENSHOTS = [
  {
    icon: ic_build,
    title: 'Data Producers',
    image: DataProducers,
  },
  {
    icon: ic_code,
    title: 'Developers',
    image: Developers,
  },
  {
    icon: ic_cloud_download,
    title: 'Data Consumers',
    image: DataConsumers,
  }
];
